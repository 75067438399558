import React from 'react'
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
function Reward() {
  return (
    <>
         <Navbar></Navbar>
         <section className='reward'>
            <div className='container'>
                <h2>Earn Crypto</h2>
                <p>Earn APRIL Token rewards when you sign up and use REAP.   <br></br> </p>
            </div>
         </section>
         <Footer></Footer>
    </>
  )
}

export default Reward
