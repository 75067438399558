import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import PhraseScreenShot from "../img/phrase-screenshot.svg";

function SecureYourWalletPhrase() {
  return (
    <div>
      <Navbar></Navbar>
      <section className='how'>
        <div className='container'>
          <h1 className='phraseHeader'>Secure Your Crypto Wallet Seed Phrases</h1>
          <p className='phraseParagraph'>
            Backup and keep safe your crypto wallet seed phrases. REAP backup is secure using world leading encryption. REAP encrypts your wallet seed phrases directly on your device.
          </p>

          <img
            className='phrase-screenshot'
            src={PhraseScreenShot}
            height='300px'
            width='500px'
            alt='Phrase Screenshot'
          ></img>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default SecureYourWalletPhrase;
