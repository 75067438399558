import React from "react"
import Navbar from "../components/Navbar.js"
import Footer from "../components/Footer.js"
import { ConnectWallet } from "@thirdweb-dev/react"

function How() {
  return (
    <div>
      <Navbar></Navbar>
      <section className="how">
        <div className="container">
          <h1>How To Get Reap</h1>
          <div className="how_row">
            <div className="how_col">
              <h2>
                <span>1</span> Download Our Mobile App
              </h2>
              <p>
                Safeguard your crypto journey with our mobile app. It's your secure seed phrase vault. Rest easy knowing
                your crucial crypto information is protected, even on the go,
                and experience the freedom of storing your wallet seeds from
                your mobile device.
              </p>
              <a href="#/">Download</a>
            </div>
            <div className="how_col">
              <h2>
                <span>2</span> Purchase Membership
              </h2>
              <p>
                Choose the membership that suits your needs
                and enjoy a new level of crypto convenience.
              </p>
              <a href="#/">Choose a Plan</a>
            </div>
            <div className="how_col">
              <h2>
                <span>3</span>Connect Wallet
              </h2>

              <p>
                Experience the power of
                managing your wallet seeds with ease and peace of mind.
              </p>
              <a href="#/">Connect Wallet</a> 
              {/* <ConnectWallet className="connect" />  */}
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )
}

export default How
