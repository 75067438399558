import React from "react"
import { useContract, useContractWrite } from "@thirdweb-dev/react"
import { useAddress } from "@thirdweb-dev/react"
import { aprilAddress, unlockyearly } from "../configurations/config"

export default function useCall() {
  const { contract } = useContract(aprilAddress)
  const address = useAddress()
  const { mutateAsync: approve, isLoading } = useContractWrite(
    contract,
    "approve"
  )

  const call = React.useCallback(async () => {
    const membershipPrice = "500000000000000000000"
    try {
      const data = await approve({ args: [unlockyearly, membershipPrice] })
      console.info("contract call successs", data)
    } catch (err) {
      console.error("contract call failure", err)
    }
  }, [approve])

  return call
}
