import React from "react";

// Components
import Navbar from "../components/Navbar.js";
import Popup from "../components/VidePopup.js";
import BalanceOf from "../components/BalanceOf.js";
import Faq from "../components/Faq.js";
import Footer from "../components/Footer.js";
import { Helmet } from "react-helmet";
// Icons
import Icon from "../img/icon.png";
import Icon2 from "../img/icon2.svg";
import Icon3 from "../img/icon3.svg";
import Mobile from "../img/mobile.svg";
import AppIcon from "../img/Google Play.svg";
import Membership from "../img/membership_icon.svg";
import HomeMobile from "../img/home_mobile.png";

// Third Web
import {
  useAddress,
  useContract,
  useContractWrite,
  useChainId,
} from "@thirdweb-dev/react";

// Functions
import useMonthlyCall from "../functions/useMonthlyCall.js";
import useYearlyCall from "../functions/useYearlyCall.js";

// Config
import { unlockMonthly, unlockyearly } from "../configurations/config.js";

function Home() {
  // const [referrer, setReferrer] = useState("")
  const address = useAddress();
  const chainId = useChainId();

  const { contract: monthlyContract } = useContract(
    "0xF19F3Eef84CfB45C8a1eD8d3dBBa9842e1407da0",
  );
  const { contract: yearlyContract } = useContract(unlockyearly);

  const { mutateAsync: monthlyMembership } = useContractWrite(
    monthlyContract,
    "purchase",
  );
  // const { mutateAsync: purchase } = useContractWrite(contract, "purchase")

  const { mutateAsync: yearlyMembership } = useContractWrite(
    yearlyContract,
    "purchase",
  );

  const _values = ["50000000000000000000"];
  const _valuesYearly = ["500000000000000000000"];
  const _recipients = [address];
  const _referrers = [address];
  const _keyManagers = [address];
  const _data = [0];

  const approveCall = useMonthlyCall();
  const approveCallYearly = useYearlyCall();

  // 0xF19F3Eef84CfB45C8a1eD8d3dBBa9842e1407da0
  // 0xF19F3Eef84CfB45C8a1eD8d3dBBa9842e1407da0

  const pruchaseCallMonthly = async () => {
    try {
      const data = await monthlyMembership({
        args: [_values, _recipients, _referrers, _keyManagers, _data],
      });
      if (data) {
        // Display the message with the received data
        alert("Membership Purchased");
      }
    } catch (err) {
      alert("contract call failure", err);
    }
  };

  const pruchaseCallYearly = async () => {
    try {
      const data = await yearlyMembership({
        args: [_valuesYearly, _recipients, _referrers, _keyManagers, _data],
      });
      if (data) {
        // Display the message with the received data
        alert("Membership Purchased");
      }
    } catch (err) {
      alert("contract call failure", err);
    }
  };

  const monthlyCall = async () => {
    try {
      await approveCall();
      await pruchaseCallMonthly();
    } catch (error) {
      console.error("error", error);
    }
  };

  const yearlyCall = async () => {
    try {
      await approveCallYearly();
      await pruchaseCallYearly();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <Navbar></Navbar>

      <section className='banner'>
        <div className='container'>
          <h1>REAP</h1>
          <h6>
            <span></span>Earn <span></span> <span></span>Crypto{" "}
            <span>Rewards</span>
          </h6>
          <p>
             <br></br> {" "}
          </p>
          {/* BUTTON ROW */}
          <div className='btn_row'>
            <div className='btn_col'>
              <a href='https://play.google.com/store/apps/details?id=com.reapapril'>Download REAP</a>
            </div>
            <div className='btn_col'>
              <a href='https://play.google.com/store/apps/details?id=com.reapapril'>
                Earn Crypto 
              </a>
            </div>
            <div className='btn_col'>
              <a href='https://play.google.com/store/apps/details?id=com.reapapril'>Easily Cash-Out</a>
            </div>
          </div>
        </div>
      </section>

      <section className='video_section'>
        <div className='container'>
          <div className='center'>
            <Popup></Popup>
          </div>
        </div>
      </section>

      <section className='section_3'>
        <div className='container'>
          <h2>Benefits of REAP</h2>
          <div className='row_beni'>
            <div className='col_beni'>
              <h3>Convenient</h3>
              <p>
                REAP is designed with simplicity and convenience in mind, making
                it accessible for non-technical users. Who's main device is
                mobile.{" "}
              </p>
              <img alt='Icon' src={Icon} className='beni_icon'></img>
            </div>
            <div className='col_beni'>
              <h3>Secure</h3>
              <p>
                Utilizing world-leading encryption standards, wallet phrases are
                securely encrypted right on your device, providing unmatched
                protection and peace of mind.{" "}
              </p>
              <img alt='Icon' src={Icon2} className='beni_icon'></img>
            </div>
            <div className='col_beni'>
              <h3>Under Your Control</h3>
              <p>
                Only you have the power to decrypt your encrypted phrases, and
                this can be done solely on your device, ensuring absolute
                privacy and control.{" "}
              </p>
              <img alt='Icon' src={Icon3} className='beni_icon'></img>
            </div>
          </div>

          <div className='row_app'>
            <div className='col_app_1'>
              <h1>
                Download <br></br> <span>REAP</span>{" "}
              </h1>
              <p>
              </p>

              <a href='https://play.google.com/store/apps/details?id=com.reapapril'>
                <img src={AppIcon} alt='appstore'></img>
              </a>
            </div>
            <div className='col_app_2'>
              <img src={HomeMobile} alt='mobile'></img>
            </div>
          </div>
        </div>
      </section>

      <section className='section_4'>
        <div className='container'>
          <div className='center'>
            <h1 id='membership'>Partnerships</h1>
            <p>Thirdweb Startup Program</p>
            <img alt='membership' src={Membership}></img>
          </div>
         
        </div>
      </section>

      <section className='section_faq' id='faq'>
        <div className='container'>
          <h1>Frequently Asked Questions</h1>
          <h6>Stuck on something we are here to help you</h6>
          <div className='faq_cont'>
            <Faq></Faq>
          </div>
        </div>
      </section>

      <section className='tagbox'>
        <div className='container'>
          <div
            className='taggbox'
            data-widget-id='143268'
            data-tags='false'
          ></div>
          <Helmet>
            <script
              src='https://widget.taggbox.com/embed-lite.min.js'
              type='text/javascript'
            ></script>
          </Helmet>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
}

export default Home;
