import React from "react"

const CountdownTimer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="dm-sans-font counter-box">
      <div className="layout-boxes">
        <span className="counter-value">{days}</span>
        <span className="counter-key">Days</span>
      </div>
      <div className="layout-boxes">
        <span className="counter-value">{hours}</span>
        <span className="counter-key">Hours</span>
      </div>
      <div className="layout-boxes">
        <span className="counter-value">{minutes}</span>
        <span className="counter-key">Minutes</span>
      </div>
      <div className="layout-boxes">
        <span className="counter-value">{seconds}</span>
        <span className="counter-key">Seconds</span>
      </div>
    </div>
  )
}

export default CountdownTimer
