import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import {
  ThirdwebProvider,
  walletConnect,
  metamaskWallet,
  coinbaseWallet,
  useContract,
} from "@thirdweb-dev/react"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <ThirdwebProvider
      activeChain="binance"
      clientId="0cde6a874bb767cdcbf735bc99865f86" //Enter the clientID from the thirdweb dashboard
      supportedWallets={[metamaskWallet(), coinbaseWallet(), walletConnect()]}
    >
      <App />
    </ThirdwebProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
