import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function PrivacyPolicy() {
  return (
    <div>
      <Navbar></Navbar>
      <section className='how'>
        <div className='container'>
          <h1>PRIVACY POLICY</h1>
    <h1> Last Updated: 10th January 2024 </h1>
          <p>
            Please note that this privacy statement will regularly be updated to reflect any change in the way we handle your personal information or any changes in applicable laws.
            This Privacy Notice describes the policies and procedures of April Labs, (“we,” “our,” or “us”) pertaining to the collection, use, and disclosure of your information on https://aprilreap.com and related mobile applications and products we offer (the “Services” or “REAP”).

          </p>

             <h2>OVERVIEW</h2>
          <p>
            Your right to privacy and the protection of your personal data is important to us. The following sections provide further details as to how we process your personal information through REAP. We don’t share your information with third parties except to deliver you our Services and products, comply with the law, make REAP better, protect our rights, or effectuate a business transfer. We’re not a huge, faceless corporation. We’re just developers trying to deliver an incredible product. If you have any questions or concerns about this policy, please reach out to us at info@apriloracle.com.
          </p>
              
          <h2>HOW DO YOU ACCEPT THIS POLICY?</h2>
          <p>
            By using REAP, including downloading one of our mobile applications or visiting our website, you agree to the use, disclosure, and procedures outlined in this Privacy Policy.
          </p>

          <h2>
          WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?
          </h2>
          <p>
            We do our best to minimize the amount of personal information that we collect from REAP users. Your contact information, such as your phone number, or email address (depending on how you contact us), may be collected when you communicate with us for support, or if you report a bug or other error related to REAP or when you interact with us through social media. When you use our Services, we process PUBLIC wallet addresses.
          </p>
              <h2>Category of personal information </h2>
          <p>
            1. Personal details, contact details, and identifiers.
          </p>
              <p>
           2. System and application access data and internet and electronic network activity information.
          </p>
            <h2>Types of personal information captured by category</h2>
               <p>
            1. Name and contact details (such as e-mail, phone numbers when you voluntarily have shared this information with us in order to be contacted)
          </p>
              <p>
           2. The Internet protocol (IP) address used to connect your computer or mobile device to the Internet. REAP metrics (e.g., the occurrences of technical errors, your interactions with service features and content and your setting preferences) Records of any contractual agreements between yourself and REAP
          </p>
             <h2>Purpose</h2>
                <p>
            1. Facilitating communication with you for customer support purposes.
          </p>
              <p>
           2. Provide troubleshooting, and improve REAP Services. To analyse performance, fix errors, and improve the usability and effectiveness of REAP services. Necessary for the performance of a contract to which you are a party.
          </p>
         <h2>Legal Basis (UK & GDPR)</h2>
                <p>
            1. Our legitimate interests in ensuring proper communication and handling within the organization. Your consent when we ask for it to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your data for that purpose.
          </p>
              <p>
           2. Our legitimate interests and the interests of our users in, for example, detecting and preventing fraud and abuse in order to protect the security of our users, ourselves, or others. Our legitimate interests in keeping records and necessary for the performance of your contract.
          </p>

              
        <h2>
WILL WE SHARE YOUR PERSONAL DATA WITH THIRD PARTIES?
</h2>
          <p>
            Information about our users is an important part of our business and we are not in the business of selling our users’ personal information to others. We may transfer personal data to our service providers or third parties in connection with REAP’s operation of its business, as certain features on REAP rely on various third-party products and services (collectively “Third Party Services”). These third-party services providers only have access to certain Personal Information, such as your public wallet addresses, to perform their functions and may not use it for other purposes. Furthermore, they must process the personal information in accordance with our contractual agreements and only as permitted by applicable data protection laws. 
          </p>

          <h2>
HOW WE USE THE INFORMATION WE GATHER
</h2>
          <p>
            We primarily use the limited information that we collect to enhance REAP. Except as otherwise described below, we do not rent, trade, or sell your Personal Information. Some ways we may use your Personal Information are to:

- Contact you when necessary;
- Respond to your comments, questions, or issues related to bugs or errors with REAP;
- Provide you with additional information;
- Send you information and marketing materials about services and products available through REAP, using push notifications or other means;
- Train our team members;
- Other internal business purposes.

          </p>

  <h2>
Aggregated Personal Data and Non-Personal Information
</h2>
          <p>
            We may share or disclose aggregated Personal Data or Non-Personal Information with service providers or with other persons we conduct business with, including but not limited potential third-parties for the purpose of showcasing the performance of the company. These service providers and other persons may also share with us aggregated Non-Personal Information that they have independently developed or acquired. Additionally, we may combine aggregate information from the pixel tags, and web beacons, with similar data we collect from other visitors to help us improve our Services. When doing so, we do our best to ensure that aggregated information cannot be linked back to you.
          </p>

  <h2>
Agents or Third Party Partners
</h2>
          <p>
            We may provide your Personal Information to our employees, contractors, agents, service providers, and designees (“Agents”) to enable them to perform certain services for us, including improvement of website-related services and features, and performance of maintenance services.
          </p>
            
  <h2>Protection of Us and Others</h2>
          <p>
            We will share personal information outside of REAP if we have a reasonable belief that access, use, preservation, or disclosure of the information is reasonably necessary to comply with any applicable law, regulation, legal process, or enforceable governmental request; to cooperate with law enforcement; to enforce or apply our Terms of Use and other agreements; or to protect the rights, property, or safety of REAP, our employees, our users, or others.
          </p>
            
  <h2>YOUR RIGHTS WITH RESPECT TO THE PROCESSING OF PERSONAL DATA</h2>
          <p>
            You are entitled (under the conditions, and subject to the exceptions, set out in applicable law) to:
Request Access to the personal information we process about you: You can request access to the information we have collected from you. You can do this by contacting us at info@apriloracle.com. We will provide you with a copy of the data we process about you. To comply with your request, we may ask you to verify your identity. We will fulfill your request by sending your copy electronically. For any subsequent access request, we may charge you an administrative fee.
Request a rectification of your personal data: If you believe that the information we have collected is incorrect or incomplete, you may contact us so we can update it and keep your data accurate.
Object to the processing of your personal data: You may request that we no longer process your personal data.
Request to erase your personal data: You may request the erasure of your personal data, including where such personal data would no longer be necessary to achieve the purposes for which it was collected. Any data that is no longer needed for purposes specified in the “How We Use the Information We Gather” section will be deleted after ninety (90) days. Wallet addresses created through the REAP application cannot be deleted from the Ethereum blockchain, therefore we are unable to delete this personal information. If at any point you wish for REAP to delete information about you, you may contact us at info@apriloracle.com.
Request the restriction of the processing of your personal data: You may request that REAP only processes your personal data in limited circumstances, including with your consent. Please note that we do not sell your personal data.

          </p>
<h2>DATA RETENTION</h2>
          <p>
            Please note that even if you delete your wallet or addresses from the REAP mobile application, uninstall the REAP mobile applications from your device, or request that your information be deleted, we still may retain some information that you have provided to us to maintain REAP or to comply with the laws and regulations to which REAP is subject. If you have any questions or objections as to how we collect and process your personal information, please contact info@apriloracle.com.
          </p>
<h2>DATA SECURITY</h2>
          <p>
            We are committed to making sure your information is protected in accordance with applicable laws and our data privacy policies. We have selected third-party vendors that use the Ethereum network. Unfortunately, we do not control these third parties and therefore cannot guarantee complete security. We work to protect the security of your personal information during transmission by using encryption protocols and software. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of your personal information and secure all connections with industry-standard transport layer security. Even with all these precautions, we cannot fully guarantee against the access, disclosure, alteration, or deletion of data through events, including but not limited to hardware or software failure or unauthorized use. Any information that you provide to us is done so entirely at your own risk.
          </p>
            
  <h2>CHILDREN</h2>
          <p>
            We are especially sensitive about children’s information. Our services are not targeted towards children, and our users must be at least eighteen (18) years old to use our services. We don’t knowingly collect information from children under the age of 13. If you are a parent or legal guardian of a minor child, we will treat any information that you provide us while using REAP on behalf of your minor child as personal information as otherwise provided in this Privacy Policy. If you have questions concerning our information practices with respect to children, or if you learn that a child under the age of 13 has used REAP, created a user account, or provided us with personal information, please email us at info@apriloracle.com.
          </p>
            
  <h2>CONDITIONS OF USE, NOTICES, CHANGES AND UPDATES TO PRIVACY NOTICE</h2>
          <p>
            If you choose to use REAP, your use and any dispute over privacy is subject to this notice and our Terms of Use. If you have any concerns about privacy at REAP, please contact us with a complete description, and we will try to resolve it. You also have the right to contact your local Data Protection Authority. We reserve the right to update and revise this Privacy Policy at any time. We occasionally review this Privacy Policy to make sure it complies with applicable laws and conforms to changes in our business. If we do revise this Privacy Policy, we will update the “Effective Date” at the top of this page so that you can tell if it has changed since your last visit and will do our best to notify you. Please review this Privacy Policy regularly to ensure that you are aware of its terms. Any use of REAP after an amendment to our Privacy Policy constitutes your acceptance of the revised or amended terms.
          </p>

          <h2>QUESTIONS</h2>
          <p>We’d be happy to answer them. Shoot us an email:
Email: info@apriloracle.com
</p>

          

      

         
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default PrivacyPolicy;
