import React, { useState, useEffect } from "react"

// Components
import Navbar from "../components/Navbar.js"
import Footer from "../components/Footer.js"

// Third Web
import { useContract, useContractRead } from "@thirdweb-dev/react"
import { useAddress } from "@thirdweb-dev/react"

// Config
import { unlockMonthly, unlockyearly } from "../configurations/config"

// Expiration
import MonthlyCounter from "../components/MonthlyCounter"
import YearlyCounter from "../components/YearlyCounter"

function Membership() {
  const address = useAddress()
  const { contract } = useContract(unlockMonthly)
  const { contract: contractYearly } = useContract(unlockyearly)
  const { data, isLoading } = useContractRead(contract, "balanceOf", [address])
  const { data: dataYearly, isLoading: loadingYearly } = useContractRead(
    contractYearly,
    "balanceOf",
    [address]
  )

  // const [countdownDate, setCountdownDate] = useState(
  //   new Date("04/09/2024").getTime()
  // )

  // const [timer, setTimer] = useState({
  //   days: 0,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // })

  // useEffect(() => {
  //   setInterval(() => setNewTime(), 1000)
  // }, [])

  // const setNewTime = () => {
  //   if (countdownDate) {
  //     const currentTime = new Date().getTime()

  //     const distanceToDate = countdownDate - currentTime

  //     let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
  //     let hours = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     )
  //     let minutes = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
  //     )
  //     let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

  //     const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  //     days = `${days}`
  //     if (numbersToAddZeroTo.includes(hours)) {
  //       hours = `0${hours}`
  //     } else if (numbersToAddZeroTo.includes(minutes)) {
  //       minutes = `0${minutes}`
  //     } else if (numbersToAddZeroTo.includes(seconds)) {
  //       seconds = `0${seconds}`
  //     }

  //     setTimer({ days: days, hours: hours, minutes, seconds })
  //   }
  // }
  return (
    <div>
      <Navbar></Navbar>
      <section className="author-font">
        <div className="container">
          <h1 style={{ textAlign: "center", marginTop: "127px" }}>
            Membership
          </h1>

          <div className="text-center flex flex-col justify-center items-center">
            {!isLoading || !loadingYearly ? (
              parseInt(data, 16) !== 0 || parseInt(dataYearly, 16) !== 0 ? (
                <>
                  {parseInt(data, 16) !== 0 && <MonthlyCounter />}
                  {parseInt(dataYearly, 16) !== 0 && <YearlyCounter />}
                </>
              ) : (
                <>
                  <p>You are not a member</p>
                </>
              )
            ) : (
              <p>Loading....</p>
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )
}

export default Membership
