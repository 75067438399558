// Pages
import Home from "./pages/Home.js"
import How from "./pages/How.js"
import Membership from "./pages/Membership.js"

// BOOTSTRAP CSS
import "bootstrap/dist/css/bootstrap.min.css"

// Styles
import "./App.css"
import "./css/author.css"

// React Router Dom
import { Routes, Route } from "react-router-dom"
import PrivacyPolicy from "./pages/PrivacyPolicy.js"
import TermOfService from "./pages/TermOfService.js"
import SecureYourWalletPhrase from "./pages/SecureYourWalletPhrase.js"
import Reward from "./pages/Reward.js"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/how-to-get-reap" element={<How />} /> */}
        <Route path="/membership" element={<Membership />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-service" element={<TermOfService />} />
        <Route
          path="/secure-your-wallet-phrase"
          element={<SecureYourWalletPhrase />}
        />
        <Route path="/reap-reward" element={<Reward />} />
      </Routes>
    </div>
  )
}

export default App
