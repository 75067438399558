import React, { useEffect, useState } from "react"

// Third Web
import { useContract, useContractRead } from "@thirdweb-dev/react"
import { useAddress } from "@thirdweb-dev/react"

// Token Id
import useCall from "./getMonthlyTokenId"

// Icon
import MembershipIcon from "../img/membership_icon.svg"

// Components
import CountdownTimer from "./CountdownTimer"

// Config
import { unlockMonthly } from "../configurations/config"

const MonthlyCounter = () => {
  const { contract } = useContract(unlockMonthly)

  const [expTime, setExpTime] = useState()
  const [expDate, setExpDate] = useState()

  const address = useAddress()
  const [tokenId, setTokenId] = useState()
  const { data, isLoading } = useContractRead(
    contract,
    "keyExpirationTimestampFor",
    [tokenId]
  )

  const tokenIDofOwner = useCall()
  const tokeninString = tokenIDofOwner?.toString()

  // Counter Logic
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const setNewTime = () => {
    if (expTime) {
      const currentTime = new Date().getTime()
      const distanceToDate = expTime - currentTime

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      )
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

      days = `${days}`
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`
      }

      setTimer({ days: days, hours: hours, minutes, seconds })
    }
  }

  useEffect(() => {
    setTokenId(tokeninString)
    if (data) {
      const isTime = new Date(data?.toNumber() * 1000).getTime()
      const isDate = new Date(data?.toNumber() * 1000).toString().slice(4, 15)

      setExpTime(isTime)
      setExpDate(isDate)
      // console.log(isTime, "isTime")
      if (isDate === "Jan 01 1970") {
        setExpDate("Not a Member")
      }
      if (isDate === "lid Date") {
        setExpDate("Not a Member")
      }
    }
  }, [data, tokeninString])

  useEffect(() => {
    let val
    if (expTime) {
      val = setInterval(() => {
        setNewTime()
      }, 1000)
    }
    return () => clearInterval(val)
  }, [expTime])

  return (
    <div className="py-6 counter-container">
      {!isLoading &&
        expTime &&
        (expTime !== "0" ? (
          <div style={{ width: "100%" }}>
            <div className="mobile-counter">
              <div className="counter-header">
                <div style={{ display: "flex" }}>
                  <img alt="membership" height={47} src={MembershipIcon}></img>

                  <div className="dm-sans-font" style={{ margin: "0 14px" }}>
                    <span className="counter-heading">Monthly</span>
                    <br /> <span className="counter-text">10$ per month</span>
                  </div>
                  <div className="inter-font counter-amount">10$</div>
                </div>
                <div style={{ width: "100%" }}>
                  <h2 className="time-remaining">Time Remaining</h2>
                  <p className="dm-sans-font time-text">
                    Time Remaining to end of membership Plan
                  </p>
                  <CountdownTimer
                    days={timer?.days}
                    hours={timer?.hours}
                    minutes={timer?.minutes}
                    seconds={timer?.seconds}
                  />
                </div>
              </div>

              <div className="dm-sans-font plan-container">
                <h3 className="plan-heading">Membership</h3>
                <div className="plan-box">
                  <div className="plan-layout">
                    <span>Ends</span>
                    <span>{expDate && expDate}</span>
                  </div>

                  <div className="plan-layout">
                    <span>Status</span>
                    <span
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Active Plan
                    </span>
                  </div>
                </div>
                <button className="manage-plan-mobile-btn">Manage Plan</button>
              </div>
            </div>
            <div className="desktop-counter">
              <div className="desktop-header">
                <div style={{ display: "flex" }}>
                  <img alt="membership" height={47} src={MembershipIcon}></img>

                  <div className="dm-sans-font" style={{ margin: "0 14px" }}>
                    <span className="counter-heading">Monthly</span>
                    <br /> <span className="counter-text">10$ per month</span>
                  </div>
                  <div className="inter-font counter-amount">10$</div>
                </div>
                <h2 className="time-remaining">Time Remaining</h2>
                <p className="dm-sans-font time-text">
                  Time Remaining to end of membership Plan
                </p>
                <div className="desktop-counter-box">
                  <div className="desktop-layout-boxes-container">
                    <div className="desktop-layout-boxes">
                      <span className="desktop-plan-amount">{timer?.days}</span>
                      <span className="desktop-plan-key">Days</span>
                    </div>
                    <div className="desktop-layout-boxes">
                      <span className="desktop-plan-amount">
                        {timer?.hours}
                      </span>
                      <span className="desktop-plan-key">Hours</span>
                    </div>
                    <div className="desktop-layout-boxes">
                      <span className="desktop-plan-amount">
                        {timer?.minutes}
                      </span>
                      <span className="desktop-plan-key">Minutes</span>
                    </div>
                    <div className="desktop-layout-boxes">
                      <span className="desktop-plan-amount">
                        {timer?.seconds}
                      </span>
                      <span className="desktop-plan-key">Seconds</span>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "32.25px",
                      fontSize: "10.75px",
                      fontWeight: "700",
                      color: "#1E2022",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="dm-sans-font"
                  >
                    <div className="status-container">
                      <span className="status-key">Ends</span>
                      <span className="status-value">{expDate && expDate}</span>
                    </div>

                    <div className="status-container">
                      <span className="status-key">Status</span>{" "}
                      <span className="status-value">Active Plan</span>
                    </div>
                  </div>
                </div>

                <div className="dm-sans-font desktop-buttons-container">
                  <button className="desktop-renew-btn">
                    Renew Current Plan
                  </button>
                  <button className="desktop-manage-btn">Manage Plan</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <p>TokenId doesnt exist yet</p>
          </>
        ))}
    </div>
  )
}

export default MonthlyCounter
